/* eslint-disable */
import { base } from '@/utils/api'

const ENDPOINT = '/simpro-updates'

export default {
    getItems(page, clinicId, simproPropId, query) {
        const config = {
            params: { page, clinicId, query}
        };
        return base.get(`${ENDPOINT}/${simproPropId}/simpro`, config);
    },
    getIds(simproPropId) {
        const config = {
            params: { simproPropId }
        };
        return base.get(`${ENDPOINT}/batch/ids`, config);
    },
    create(props) {
        return base.post(`${ENDPOINT}`, props);
    },
    update(id, props) {
        return base.put(`${ENDPOINT}/${id}`, props);
    },
    delete(id) {
        return base.delete(`${ENDPOINT}/${id}`);
    },
    deleteBatch(ids) {
        return base.put(`${ENDPOINT}/batch/delete`, { ids });
    },
}